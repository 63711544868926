import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = ({ setIsChatOpen, handlePlatformSelect }) => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center text-center text-md-start">
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <h5 className="footer-brand">BuzzBoostr</h5>
            <p className="footer-description">
              Take your social media reach to new heights!
            </p>
          </Col>
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <h6 className="footer-title">Quick Links</h6>
            <ul className="footer-links">
              <li><Link to="/" onClick={() => handlePlatformSelect('Instagram')}>Place New Order</Link></li>
              <li><Link to="/order-tracking">Order Tracking</Link></li>
              <li><span onClick={()=>setIsChatOpen(true)} className="footer-link">FAQ & Support</span></li>
            </ul>
          </Col>
          <Col xs={12} md={4}>
            <h6 className="footer-title">Connect with Us</h6>
            <div className="social-icons">
              <a href="https://facebook.com" className="social-icon"><FaFacebookF /></a>
              <a href="https://twitter.com" className="social-icon"><FaTwitter /></a>
              <a href="https://instagram.com" className="social-icon"><FaInstagram /></a>
              <a href="https://linkedin.com" className="social-icon"><FaLinkedinIn /></a>
              <a href="https://youtube.com" className="social-icon"><FaYoutube /></a>
            </div>
          </Col>
        </Row>
        <hr className="footer-divider" />
        <Row>
          <Col className="text-center">
            <p className="footer-copy">&copy; 2024 BuzzBoostr. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
