import React from 'react';
import './FooterIcon.css';
import chatIcon from '../assets/chat-icon.png'; // You can replace this with faqIcon if you prefer

const FooterIcon = ({ setIsChatOpen }) => {
  return (
    <div className="footer-icon">
      <img src={chatIcon} alt="Chat with us" className="icon" onClick={() => setIsChatOpen(true)} />
    </div>
  );
};

export default FooterIcon;
